import React from "react";
import HeaderTwo from "../common/header/HeaderTwo";
import HeroThree from "../components/hero/HeroThree";
import FeatureOne from "../components/features/FeatureOne";
import ServiceOne from "../components/services-part/ServiceOne";
import AboutOne from "../components/about-part/AboutOne";
import ProjectThree from "../components/projects-part/ProjectThree";
import FeatureFive from "../components/features/FeatureFive";
import TestimonialOne from "../components/testimonial/TestimonialOne";
import FooterOne from "../common/footer/FooterOne";
import HeaderThree from "../common/header/HeaderThree";

const Home = () => {
  return (
    <>
      {/* <HeaderTwo /> */}
      <HeaderThree />
      <HeroThree />
      <FeatureOne />
      <ServiceOne />
      <AboutOne />
      <ProjectThree />
      <FeatureFive />
      <TestimonialOne />
      <FooterOne />
    </>
  );
};

export default Home;

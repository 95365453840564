import React from "react";
import { Link } from "react-router-dom";

export default class HeroThree extends React.Component {
  componentDidMount() {
    const $ = window.$;

    if ($(".slider-carousel").length) {
      $(".slider-carousel").owlCarousel({
        animateOut: "fadeOut",
        animateIn: "fadeIn",
        loop: true,
        margin: 0,
        nav: false,
        singleItem: true,
        smartSpeed: 500,
        autoplay: true,
        autoplayTimeout: 6000,
        navText: [
          '<span class="icon-right-arrow"></span>',
          '<span class="icon-right-arrow"></span>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1024: {
            items: 1,
          },
        },
      });
    }
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <section className="slider-one-sec">
          <div className="slider-carousel owl-carousel owl-theme">
            {/* Start Slider One Single */}
            <div className="slider-one__single">
              <div
                className="image-layer"
                style={{
                  backgroundImage:
                    "url(" + publicUrl + "assets/images/slider/a.jpg)",
                }}
              ></div>
              <div className="container">
                <div className="row clearfix">
                  <div className="col-xl-12">
                    <div className="slider-one__single-content">
                      <h3>The profile is shown by a website</h3>
                      <h2>
                        Modern Website + <br />
                        Any Solution Devalopment
                      </h2>
                      <p>
                        Creating a high-quality website to showcase your image
                        or business image
                      </p>
                      <div className="btn-box">
                        <Link
                          to={process.env.PUBLIC_URL + `/`}
                          className="thm-btn"
                          data-text="Read More +"
                        >
                          Read More +
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider One Single */}

            {/* Start Slider One Single */}
            <div className="slider-one__single">
              <div
                className="image-layer"
                style={{
                  backgroundImage:
                    "url(" + publicUrl + "assets/images/slider/6.png)",
                }}
              ></div>
              <div className="container">
                <div className="row clearfix">
                  <div className="col-xl-12">
                    <div className="slider-one__single-content">
                      <h3>Solving Business Problems</h3>
                      <h2>
                        Software + Electronics
                        <br /> IOT Solutions
                      </h2>
                      <p></p>
                      <div className="btn-box">
                        <Link
                          to={process.env.PUBLIC_URL + `/`}
                          className="thm-btn"
                          data-text="Read More +"
                        >
                          Read More +
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider One Single */}

            {/* Start Slider One Single */}
            <div className="slider-one__single">
              <div
                className="image-layer"
                style={{
                  backgroundImage:
                    "url(" + publicUrl + "assets/images/slider/c.jpg)",
                }}
              ></div>
              <div className="container">
                <div className="row clearfix">
                  <div className="col-xl-12">
                    <div className="slider-one__single-content">
                      <h3>Next Level Solution</h3>
                      <h2>
                        Developing Any + <br />
                        Fill Your Any Requirements
                      </h2>
                      <p>
                        Any kind of system to manage the day-to-day work in the
                        company
                      </p>
                      <div className="btn-box">
                        <Link
                          to={process.env.PUBLIC_URL + `/`}
                          className="thm-btn"
                          data-text="Read More +"
                        >
                          Read More +
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider One Single */}

            {/* Start Slider One Single */}
            <div className="slider-one__single">
              <div
                className="image-layer"
                style={{
                  backgroundImage:
                    "url(" + publicUrl + "assets/images/slider/d.jpg)",
                }}
              ></div>
              <div className="container">
                <div className="row clearfix">
                  <div className="col-xl-12">
                    <div className="slider-one__single-content">
                      <h3>E-Commerce Website Solutions</h3>
                      <h2>
                        Any Stor + <br />
                        With User Friendly
                      </h2>
                      <p>
                        Being able to present and sell a product or service
                        produced in your business
                      </p>
                      <div className="btn-box">
                        <Link
                          to={process.env.PUBLIC_URL + `/`}
                          className="thm-btn"
                          data-text="Read More +"
                        >
                          Read More +
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider One Single */}
          </div>
        </section>
      </>
    );
  }
}

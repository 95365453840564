import React from "react";

export default class TestimonialOne extends React.Component {
  componentDidMount() {
    const $ = window.$;

    if ($(".testimonials-one__carousel").length) {
      $(".testimonials-one__carousel").owlCarousel({
        loop: true,
        margin: 30,
        nav: false,
        smartSpeed: 500,
        autoHeight: false,
        autoplay: true,
        dots: false,
        autoplayTimeout: 10000,
        navText: [
          '<i className="fa fa-angle-double-left" aria-hidden="true"></i>',
          '<i className="fa fa-angle-double-right" aria-hidden="true"></i>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          800: {
            items: 2,
          },
          992: {
            items: 2,
          },
          1200: {
            items: 3,
          },
        },
      });
    }
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <section className="testimonials-one-sec">
          <div className="shape1">
            <img
              src={
                publicUrl + "assets/images/shapes/testimonials-v1-shape1.png"
              }
              alt=""
            />
          </div>
          <div className="shape2">
            <img
              src={
                publicUrl + "assets/images/shapes/testimonials-v1-shape2.png"
              }
              alt=""
            />
          </div>
          <div className="container">
            <div className="sec-title text-center">
              <div className="sec-title__tagline">
                <span className="left"></span>
                <h6>Our Testimonials</h6> <span className="right"></span>
              </div>
              <h2 className="sec-title__title">Our Sweet employees Say</h2>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="testimonials-one__carousel owl-carousel owl-theme">
                  {/* Start Testimonials One Single */}
                  <div className="testimonials-one__single">
                    {/* <div className="rating">
                      <ul>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star-1"></span>
                        </li>
                      </ul>
                    </div> */}
                    <div className="text-box">
                      <p>
                        I am Harshana Weerasigne. After working as a freelancer
                        for several years, I also worked in several software
                        companies and started the onezerohub campaign based on
                        that experience.
                      </p>
                    </div>
                    <div className="client-info">
                      <div className="img-box">
                        <img
                          src={
                            publicUrl +
                            "assets/images/testimonial/harshana3.jpeg"
                          }
                          alt=""
                        />
                      </div>

                      <div className="title-box">
                        <h2>Harshana Weerasinghe</h2>
                        <span>Founder, CEO</span>
                      </div>
                    </div>
                  </div>
                  {/* End Testimonials One Single */}

                  {/* Start Testimonials One Single */}
                  <div className="testimonials-one__single">
                    {/* <div className="rating">
                      <ul>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star-1"></span>
                        </li>
                      </ul>
                    </div> */}
                    <div className="text-box">
                      <p>
                        I am K.D.H Pathiranage and electrical and electronic
                        engineer focusing on the results of designing,
                        developing and testing electronic systems and
                        components. An exceptionally skilled electronics
                        engineer with years of experience in designing, testing
                        and troubleshooting various electronic devices.
                      </p>
                    </div>
                    <div className="client-info">
                      <div className="img-box">
                        <img
                          src={
                            publicUrl + "assets/images/testimonial/kadi2.jpeg"
                          }
                          alt=""
                        />
                      </div>

                      <div className="title-box">
                        <h2>K.D.H Pathiranage</h2>
                        <span>Electrical and Electronic Engineer, B/A</span>
                      </div>
                    </div>
                  </div>
                  {/* End Testimonials One Single */}

                  {/* Start Testimonials One Single */}
                  <div className="testimonials-one__single">
                    {/* <div className="rating">
                      <ul>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star"></span>
                        </li>
                        <li>
                          <span className="icon-star-1"></span>
                        </li>
                      </ul>
                    </div> */}
                    <div className="text-box">
                      <p>
                        I'm Sachith Rupasinghe, an experienced Desktop
                        Application Developer with five years of expertise. My
                        proficiency spans software design and development, where
                        I specialize in crafting intuitive desktop applications
                        tailored to diverse industry needs.
                      </p>
                    </div>
                    <div className="client-info">
                      <div className="img-box">
                        <img
                          src={
                            publicUrl +
                            "assets/images/testimonial/sachintha2.png"
                          }
                          alt=""
                        />
                      </div>

                      <div className="title-box">
                        <h2>Sachith Rupasinghe</h2>
                        <span>Software Engineer</span>
                      </div>
                    </div>
                  </div>
                  {/* End Testimonials One Single */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

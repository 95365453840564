import React from "react";
import { Link } from "react-router-dom";

export default class ServiceOne extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <section className="services-one-sec">
          <div className="container">
            <div className="sec-title text-center">
              <div className="sec-title__tagline">
                <span className="left"></span>
                <h6>Our Services</h6> <span className="right"></span>
              </div>
              <h2 className="sec-title__title">Our Professional Services</h2>
            </div>

            <div className="row">
              {/* Start Services One Single */}
              <div className="col-xl-4 col-lg-4">
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <img
                      src={publicUrl + "assets/images/services-part/web.jpg"}
                      alt=""
                    />
                  </div>

                  <div className="services-one__single-content">
                    <div className="services-one__single-content-title">
                      <h2>
                        <Link
                          to={process.env.PUBLIC_URL + `/general-contracting`}
                        >
                          WEB Services
                        </Link>
                      </h2>
                    </div>

                    <div className="services-one__single-content-bottom">
                      <p>
                        Web development crafts digital experiences, encompassing
                        design, coding, and functionality, enabling the creation
                        of dynamic and interactive websites.
                      </p>

                      {/* <div className="services-one__single-btn">
                        <Link
                          to={process.env.PUBLIC_URL + `/general-contracting`}
                          className="thm-btn"
                          data-text="Read More +"
                        >
                          Read More +
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* End Services One Single */}

              {/* Start Services One Single */}
              <div className="col-xl-4 col-lg-4">
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <img
                      src={publicUrl + "assets/images/services-part/iot.jpg"}
                      alt=""
                    />
                  </div>

                  <div className="services-one__single-content">
                    <div className="services-one__single-content-title">
                      <h2>
                        <Link
                          to={process.env.PUBLIC_URL + `/general-contracting`}
                        >
                          IOT Services
                        </Link>
                      </h2>
                    </div>

                    <div className="services-one__single-content-bottom">
                      <p>
                        IoT services connect devices, enabling data exchange and
                        automation, fostering smart solutions for diverse
                        applications across industries worldwide
                      </p>

                      {/* <div className="services-one__single-btn">
                        <Link
                          to={process.env.PUBLIC_URL + `/general-contracting`}
                          className="thm-btn"
                          data-text="Read More +"
                        >
                          Read More +
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* End Services One Single */}

              {/* Start Services One Single */}
              <div className="col-xl-4 col-lg-4">
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <img
                      src={publicUrl + "assets/images/services-part/erp.jpg"}
                      alt=""
                    />
                  </div>

                  <div className="services-one__single-content">
                    <div className="services-one__single-content-title">
                      <h2>
                        <Link
                          to={process.env.PUBLIC_URL + `/general-contracting`}
                        >
                          ERP Services
                        </Link>
                      </h2>
                    </div>

                    <div className="services-one__single-content-bottom">
                      <p>
                        ERP integrate business processes, utilizing software to
                        streamline operations, manage resources, and enhance
                        productivity across various organizational functions.
                      </p>

                      {/* <div className="services-one__single-btn">
                        <Link
                          to={process.env.PUBLIC_URL + `/general-contracting`}
                          className="thm-btn"
                          data-text="Read More +"
                        >
                          Read More +
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* End Services One Single */}
            </div>
          </div>
        </section>
      </>
    );
  }
}

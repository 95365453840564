import React from "react";
import { Link } from "react-router-dom";

export default class ProjectThree extends React.Component {
  componentDidMount() {
    const $ = window.$;

    const images = {
      1: [
        "assets/images/update1.0/P11.png",
        "assets/images/update1.0/p12.png",
        "assets/images/update1.0/p14.png",
        "assets/images/update1.0/p15.png",
        "assets/images/update1.0/p16.png",
        "assets/images/update1.0/p17.png",
        "assets/images/update1.0/p18.png",
        "assets/images/update1.0/p19.png",
      ],
      2: [
        "assets/images/update1.0/p2-1.png",
        "assets/images/update1.0/p2-2.png",
        "assets/images/update1.0/p2-3.png",
        "assets/images/update1.0/p2-4.png",
        "assets/images/update1.0/p2-5.png",
        "assets/images/update1.0/p2-7.png",
        "assets/images/update1.0/p2-8.png",
      ],
      3: [
        "assets/images/update1.0/p3-1.png",
        "assets/images/update1.0/p3-2.png",
      ],
    };

    if ($(".project-three__carousel").length) {
      $(".project-three__carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        smartSpeed: 500,
        autoHeight: false,
        autoplay: true,
        dots: false,
        autoplayTimeout: 6000,
        navText: [
          '<i className="fa fa-angle-double-left" aria-hidden="true"></i>',
          '<i className="fa fa-angle-double-right" aria-hidden="true"></i>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          750: {
            items: 2,
          },
          1100: {
            items: 3,
          },
          1350: {
            items: 4,
          },
        },
      });
    }

    if ($(".img-popup").length) {
      $(".img-popup").each(function () {
        var groupId = parseInt($(this).attr("data-group"), 10); // Get the group ID
        var imagesForGroup = images[groupId]; // Get images array for the group ID

        if (imagesForGroup && imagesForGroup.length > 0) {
          // If images are found for the group ID
          var magnificPopupItems = imagesForGroup.map(function (imageSrc) {
            // Map each image to magnificPopup item
            return {
              src: imageSrc,
              type: "image",
            };
          });

          $(this).magnificPopup({
            items: magnificPopupItems, // Set items for magnificPopup
            gallery: {
              enabled: true,
            },
          });
        }
      });
    }
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <section className="project-three">
          <div className="container-fluid">
            <div className="sec-title text-center">
              <div className="sec-title__tagline">
                <span className="left"></span>
                <h6>LATEST PROJECT</h6> <span className="right"></span>
              </div>
              <h2 className="sec-title__title">
                Our latest completed and <br /> running projects.
              </h2>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="project-three__carousel owl-carousel owl-theme">
                  {/* Start Project Three Single */}
                  <div className="project-three__single">
                    <div className="project-three__single-img">
                      <img
                        src={
                          publicUrl + "assets/images/update1.0/P1 HEADER.png"
                        }
                        alt="#"
                      />
                      <div className="icon-box">
                        <a
                          className="img-popup"
                          href={publicUrl + "assets/images/update1.0/P11.png"}
                          data-group="1"
                        >
                          <span className="icon-plus-sign"></span>
                        </a>
                      </div>
                      <div className="content-box">
                        <p>WEb Services</p>
                        <h3>
                          <a
                            href={`https://martfury-react.nouthemestf.vercel.app/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            MartFury - E-commerce
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  {/* End Project Three Single */}
                  {/* Start Project Three Single */}
                  <div className="project-three__single">
                    <div className="project-three__single-img">
                      <img
                        src={
                          publicUrl + "assets/images/update1.0/P2 HEADER.png"
                        }
                        alt="#"
                      />
                      <div className="icon-box">
                        <a
                          className="img-popup"
                          href={publicUrl + "assets/images/update1.0/P2-1.png"}
                          data-group="2"
                        >
                          <span className="icon-plus-sign"></span>
                        </a>
                      </div>
                      <div className="content-box">
                        <p>WEb Services</p>
                        <h3>
                          <a
                            href={`https://preview.themeforest.net/item/newsprk-react-nextjs-blog-magazine-template/full_screen_preview/44266140?_ga=2.205822675.1318727953.1707739761-1247121952.1682816548&_gac=1.192855640.1707758803.Cj0KCQiAoKeuBhCoARIsAB4Wxte0usIjTu3ffFCnVRFz6SmPcX8Hco688gtmn5L-Fg-ojN8YAGKj0ncaAkqQEALw_wcB`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Newsprk - Blog
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  {/* End Project Three Single */}
                  {/* Start Project Three Single */}
                  <div className="project-three__single">
                    <div className="project-three__single-img">
                      <img
                        src={
                          publicUrl + "assets/images/update1.0/P3 HEADER.png"
                        }
                        alt="#"
                      />
                      <div className="icon-box">
                        <a
                          className="img-popup"
                          href={publicUrl + "assets/images/update1.0/P3-1.png"}
                          data-group="3"
                        >
                          <span className="icon-plus-sign"></span>
                        </a>
                      </div>
                      <div className="content-box">
                        <p>ERP Services</p>
                        <h3>
                          <a
                            href={`https://adminlte.io/themes/v3/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            AdminLTE - Management Systems
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  {/* End Project Three Single */}
                </div>
              </div>

              <div className="col-xl-12">
                <div className="project-three-more-project-btn text-center">
                  <div className="btn-box">
                    <Link
                      href="/portfolio"
                      className="thm-btn"
                      data-text="View All Portfolio +"
                    >
                      View All project +
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
